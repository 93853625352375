<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <div class="page-content py-5">
          <div class="container">
            <div
              class="
                edc-content-round
                post
                login-content
                rounded-50
                col-lg-10 col-sm-12
                m-auto
                p-3
              "
            >
              <!--contact-form-header-->
              <div
                class="
                  contact-form-header
                  mb-4
                  text-right
                  d-block
                  border-bottom border-1
                  pb-2
                "
              >
                <h1 class="h3 fw-bold edc-color-primary p-2">
                  {{ $t("title.contact_with_us") }}
                </h1>
              </div>
              <!--//contact-form-header-->
              <!--contact-form-body-->
              <via-najat-component></via-najat-component>
              <div
                class="
                  contact-form-body
                  p-3
                  d-lg-flex
                  justify-content-between
                  align-items-top
                "
              >
                
                <div class="contact-info w-lg-50 col-lg-6 col-md-12 col-12">
                  <ul class="contact-info-list">
                    <li
                      class="d-flex justify-content-start align-items-center"
                      data-aos="fade-up"
                      data-aos-duration="700"
                    >
                      <i class="fab fa-whatsapp fs-4"></i>
                      <span class="h5 ms-3 text-muted" dir="ltr">
                        <a
                          v-for="mob in site_info.contact.mobile.split(',')"
                          :key="mob"
                          :href="`tel:+${mob}`"
                          >{{ mob }} |
                        </a>
                      </span>
                    </li>
                    <li
                      class="d-flex justify-content-start align-items-center"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      <i class="fas fa-map-marker-alt fs-4"></i>
                      <span class="h5 ms-3 text-muted">{{
                        site_info.contact.address
                      }}</span>
                    </li>
                    <li
                      class="d-flex"
                      data-aos="fade-up"
                      data-aos-duration="900"
                    >
                      <a
                        :href="`tel:+${site_info.contact.agent_mobile}`"
                        class="btn edc-btn-secondary text-white rounded-pill"
                      >
                        <i class="fas fa-headphones-alt fs-4"></i>
                        {{ $t("btn.call_representative") }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  class="contact-map col-lg-6 col-md-12 col-12"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13912.281710233437!2d48.0010918!3d29.3389317!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x61e0f941c8b05501!2sAl%20Najat%20Charity!5e0!3m2!1sen!2skw!4v1634451225552!5m2!1sen!2skw"
                    style="max-width: 600"
                    allowfullscreen=""
                    loading="lazy"
                    height="350"
                  ></iframe>
                </div>
              </div>
              <!--//contact-form-body-->
              <!--contact-form-bottom-->
              <div class="contact-form-bottom">
                <div class="post-comment-form">
                  <h1 class="h4 edc-color-primary mb-3">
                    {{ $t("title.send_to_us") }}
                  </h1>
                  <form>
                    <div
                      class="alert alert-danger"
                      v-if="errorMessage !== null"
                    >
                      {{ errorMessage }}
                    </div>
                    <div class="input-group mb-3">
                      <input
                        v-model="form.name"
                        type="text"
                        class="form-control rounded-pill py-3"
                        :placeholder="$t('input_label.name')"
                        aria-label="Name"
                        aria-describedby="name"
                      />
                    </div>
                    <div class="input-group mb-3">
                      <input
                        v-model="form.email"
                        type="email"
                        class="form-control rounded-pill py-3"
                        :placeholder="$t('input_label.email')"
                        aria-label="Email"
                        aria-describedby="email"
                      />
                    </div>
                    <div class="input-group mb-3">
                      <textarea
                        v-model="form.message"
                        class="form-control rounded-30 py-3"
                        id="message"
                        :placeholder="$t('input_label.message')"
                        rows="3"
                      ></textarea>
                    </div>
                    <div class="d-grid gap-1 col-3 mx-auto" data-aos="zoom-in">
                      <button
                        @click.prevent="sendMessage()"
                        class="
                          btn
                          edc-btn-outline-secoundry edc-color-secondary
                          rounded-30
                          py-3
                        "
                        type="button"
                      >
                        {{ $t("btn.send_message") }}
                        <i class="far fa-paper-plane fs-5"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!--//contact-form-bottom-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import ViaNajatComponent from "../components/ViaNajatComponent.vue";
export default {
  name: "abouts-page",
  components: {ViaNajatComponent},
  data() {
    return {
      form: {},
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters("site", ["site_info"]),
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    async sendMessage() {
      await axios
        .post("send-message", this.form)
        .then((response) => {
          if (response.status === 200) {
            this.errorMessage = null;
            this.$swal({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.form = {};
          }
          //console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            this.errorMessage = error.response.data.message;
            //console.log(error.response.data.message);
          }
        });
    },
  },
  created() {},
  metaInfo() {
    return {
      titleTemplate: this.$t("title.about") + " | %s",
      meta: [{ property: "og:title", content: this.$t("title.about") }],
    };
  },
  watch: {
    user: {
      deep: true,
      handler(newvalue) {
        //console.log("The list of colours has changed!");
        //this.form.name = newvalue.user_nicename;
        // this.form.email = newvalue.user_email;
      },
    },
  },
};
</script>