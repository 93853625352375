<template>
    <div class="container py-5">
        <chat-box></chat-box>
    </div>
  
</template>
<script>
  import ChatBox from "../components/Chat/ChatBox.vue";

  export default {
      name: "chat",
      components: {ChatBox},
  }
</script>
<style scoped lang="css">

</style>