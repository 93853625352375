<template>
    <div>
        <ol class="chatBox border bg-light" id="chatBox">
            {{response}}
            <li v-for="(completion,index) in completions" :key="index">
                
                <div class="m-2 p-2 rounded flex-row align-items-center" :class="{'bg-info':completion.role === 'assistant','bg-warning': completion.role !== 'assistant'}">
                    {{completion.role}}
                    <div v-html="completion.content">
                   
                    </div>
                </div>
               
            </li>
        </ol>
        
        <div class="my-1">
          <div class="d-flex align-items-stretch justify-content-between gap-1 ">
            <textarea @keydown="isTyping = true" @keyup="isTyping = false" v-model="message" class="flex-fill form-control"></textarea>
              <button :disabled="disable" @click.stop="SendMessage()" type="button" class="btn btn-light">
                  <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  <span>Ask</span>
              </button>
          </div>
        </div>
        <div v-if="isShow">... loading</div>
        <div v-if="isTyping">user typing....</div>
    </div>
    
</template>
<script>

import app_config from "../../config";
import LoadingPage from "../LoadingPage.vue";
import axios from "axios";
import { Configuration, OpenAIApi } from "openai";
export default {
    name: "chat-box",
    components: {LoadingPage},
    data(){
        return{
            isShow:false,
            isTyping: false,
            completions:[],
            message: "EDC",
            response : "",
            model: "gpt-3.5-turbo",
            disable : false,
        }
    },
    methods:{
        SendMessage : async function(){
            this.isShow = true;
            this.disable = true;
            this.completions.push({
                "role" : "user",
                "content" : this.message
            });
            this.scrollDown();
            
             await axios.post("https://api.openai.com/v1/chat/completions",{
                 "model" : this.model,
                 "messages" : [
                     {
                         "role":"user",
                         "content":this.message
                     }
                 ]
                 
             },{
                 headers:{
                     "Content-Type" : "application/json",
                     "Authorization" : "Bearer "+app_config.OPENAI_API_KEY
                 }
             }).then((response)=>{
                 
                 if(response.status === 200){
                     response.data.choices.forEach((element)=>{
                         console.log("element",element);
                         this.completions.push(element.message);
                     });
                 }
            }).finally(()=> {
                this.isShow = false;
                 this.message = "";
                this.scrollDown();
                 this.disable = false;
            });
            
        },
        FineTunes: async function(){
          
            await axios.get("https://api.openai.com/v1/fine-tunes",{
                headers:{
                    "Content-Type" : "application/json",
                    "Authorization" : "Bearer "+app_config.OPENAI_API_KEY
                }
            }).then((response)=>{

                if(response.status === 200){
                  this.response = response.data;
                }
            }).finally(()=> {
                this.isShow = false;
            });
        },
        createFineTune: async function(){
           
            const configuration = new Configuration({
                apiKey: app_config.OPENAI_API_KEY,
            });
            const openai = new OpenAIApi(configuration);

            const response = await openai.createCompletion({
                model: this.model,
                prompt: "edc address : Kuwait - Al-Rawda - Block (4) Street 42 branching from Abu Musa Al-Ashari Street, House No. 15 ,The EDC Committee is an advocacy and educational committee affiliated to Al-Najat Charitable Association. The committee was established in 2012, to become one of the most important and largest advocacy institutions in the Islamic world. The committee aims to introduce Islam - Teaching and publishing the Noble Qur’an and its translations and its sciences - Teaching and caring for new converts - Employing the latest technological means in the service of calling to God",
                temperature: 0,
                max_tokens: 7,
            });
            /*
            await axios.post("https://api.openai.com/v1/completions",{
                "prompt": "edc address : Kuwait - Al-Rawda - Block (4) Street 42 branching from Abu Musa Al-Ashari Street, House No. 15 ,The EDC Committee is an advocacy and educational committee affiliated to Al-Najat Charitable Association. The committee was established in 2012, to become one of the most important and largest advocacy institutions in the Islamic world. The committee aims to introduce Islam - Teaching and publishing the Noble Qur’an and its translations and its sciences - Teaching and caring for new converts - Employing the latest technological means in the service of calling to God",
                "model":this.model
            },{
                headers:{
                    "Content-Type" : "application/json",
                    "Authorization" : "Bearer "+app_config.OPENAI_API_KEY
                }
            }).then((response)=>{

                if(response.status === 200){
                    this.response = response.data;
                }
            }).finally(()=> {
                this.isShow = false;
            });*/
        },
        scrollDown: ()=>{
            setTimeout(() => {
                let objDiv = document.getElementById("chatBox");
                objDiv.scrollTop = objDiv.scrollHeight;
            },0);
        }
    },
    created() {
        //this.createFineTune();
       // this.FineTunes();
    }
}
</script>
<style scoped >
.chatBox{
  height: 300px;
  overflow: auto;
    padding: 0;
    margin: 0;
}

</style>